import { ColumnDisplayDescriptor, DataPropertyDescriptor, DataSourceDisplayTo, FormDefinitionMetadataIdentifiers, TableConfigColumn } from '@unifii/library/common';
import { DataSourceType, Table } from '@unifii/sdk';

import { Config } from 'config';

/**
 * Check if the Table allows 'export' for any of the roles in input
 * @param table
 * @param roles of the user in context
 */
export const checkExportAllowedForRoles = (table: Table, roles: string[]): boolean => {
    if (table.exportVisibleTo && table.exportVisibleTo.length > 0) {
        return roles.some((role) => table.exportVisibleTo?.includes(role));
    }

    return true;
};

/**
 * Check if the showCount feature is enable for this table
 * @param config
 * @param table
 * @returns
 */
export const checkShowCount = (config: Config, table: Table): boolean =>
    !!(config.unifii.tenantSettings?.features.indexing && table.showCount);

/**
 * Extract the list of identifiers to be used in the RQL include operator (current implementation works only with FormData)
 * @param columns - the table columns
 * @param propertyDescriptors - map of DataPropertyDescriptors
 * @param customColumns - custom (projection) columns
 * @returns data values' identifiers needed to display the table
 */
export const getTableRQLIncludeIdentifiers = <T>(columns: TableConfigColumn<T>[] | undefined, propertyDescriptors: Map<string, DataPropertyDescriptor>, customColumns?: ColumnDisplayDescriptor[] | undefined): string[] => {
    const customColumnsNames = customColumns?.map((cd) => cd.name) ?? [];

    const entityProperties = (columns ?? [])
    .filter((c) => !customColumnsNames.includes(c.name))
    .map((c) => {
        const identifier = c.name;
        const property = propertyDescriptors.get(identifier);

        if (!property?.sourceConfig ||
            // unfortunately _createdBy and _lastModifiedBy are faked as SourceConfig in DDE to provide advanced filter support
            (property.sourceConfig.type === DataSourceType.Users || [`${FormDefinitionMetadataIdentifiers.CreatedBy}`, `${FormDefinitionMetadataIdentifiers.LastModifiedBy}`].includes(property.identifier))
        ) {
            return identifier;
        }

        // map DS field identifier to the mapped output identifiers `_display`
        return `${identifier}.${DataSourceDisplayTo}`;
    });

    return [...new Set(entityProperties)];
};
