import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, SharedTermsTranslationKey, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { DataType, Dictionary, ErrorType, OAuthWithPassword, TenantSettings, UfRequestError, coerceDataToTarget, ensureUfRequestError, isDictionary, isMfaErrorData, isPasswordChangeRequiredErrorData } from '@unifii/sdk';
import { Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { Config, Environment } from 'config';
import { MFAPath, PasswordChangePath, ProjectSelectionPath, TenantSelectionPath, UserAccessRootPath } from 'discover/discover-constants';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { Authentication } from 'shell/services/authentication';
import { SavedUsersService } from 'shell/services/saved-users.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

import { MfaComponentNavigationState } from '../mfa.component';
import { PasswordChangeComponentNavigationState } from '../password-change.component';

@Component({
    selector: 'ud-login-form',
    templateUrl: './login-form.html',
    styleUrls: ['../../../shell/styles/external-branding.less', './login.less'],
})
export class LoginFormComponent implements OnDestroy, OnInit {

    protected readonly sharedTermsTK = SharedTermsTranslationKey;
    protected readonly commonTK = CommonTranslationKey;
    protected readonly discoverTK = DiscoverTranslationKey;
    protected formGroup: UfControlGroup;
    protected inProgress = false;
    protected rememberMeEnabled = inject(SavedUsersService).enabled;

    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private env = inject(Environment);
    private config = inject(Config);
    private auth = inject(Authentication);
    private errorService = inject(ErrorService);
    private translate = inject(TranslateService);
    private userAccessManager = inject(UserAccessManager);
    private params: { projectId?: string } = {};
    private subscriptions = new Subscription();

    ngOnInit() {

        this.formGroup = new UfControlGroup({
            username: new UfControl(ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))),
            password: new UfControl(ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))),
            rememberMe: new UfControl(),
        });

        this.subscriptions.add(
            combineLatest([this.route.params, this.route.queryParams]).pipe(
                map((results) => ({ ...results[0], ...results[1] })))
                .subscribe((allParams) => { this.paramsChange(allParams); }),
        );

    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.userAccessManager.showError(null);
    }

    protected async authorize(): Promise<void> {

        const username = (this.formGroup.controls.username?.value as string | null)?.trim();
        const password = this.formGroup.controls.password?.value as string | null;
        const rememberMe = (this.formGroup.controls.rememberMe?.value as boolean | null) ?? undefined;

        if (this.formGroup.invalid || !username || !password) {
            this.formGroup.setSubmitted();

            return;
        }

        this.inProgress = true;

        try {

            await this.auth.login({ username, password } satisfies OAuthWithPassword, rememberMe);

            void this.router.navigate(['/', UserAccessRootPath, ProjectSelectionPath, this.params]);

            this.userAccessManager.showError(null);

        } catch (e) {
            const error = ensureUfRequestError(e);

            if (isPasswordChangeRequiredErrorData(error.data)) {
                void this.router.navigate(['/', PasswordChangePath], { state: { oldPassword: password, params: this.params } satisfies PasswordChangeComponentNavigationState });

                return;
            }

            if (isMfaErrorData(error.data)) {
                const { mfaStatus, challenge, acceptedChallenges } = error.data;

                void this.router.navigate(['/', UserAccessRootPath, MFAPath], { state:
                    { mfaStatus, challenge, acceptedChallenges, password, rememberMe, params: this.params, nextRoute: ['/', UserAccessRootPath, ProjectSelectionPath] } satisfies MfaComponentNavigationState },
                );

                return;
            }

            // Ensure auth information is clear
            this.auth.clear();
            this.userAccessManager.showError(this.getAuthError(error));

        } finally {
            this.inProgress = false;
        }
    }

    protected changeTenant() {

        const { tenant } = this.config.unifii;

        // Ensure auth information is clear
        this.auth.clear();

        this.config.unifii.tenantSettings = undefined;
        this.config.unifii.tenant = undefined;

        void this.router.navigate(['/', UserAccessRootPath, TenantSelectionPath, { tenant }]);
    }

    private paramsChange(allParams: Dictionary<string>) {

        if (allParams.username) {
            this.formGroup.controls.username?.setValue(allParams.username);
        }

        const rememberMe = coerceDataToTarget(allParams.rememberMe, { type: DataType.Boolean } );

        if (rememberMe) {
            this.formGroup.controls.rememberMe?.setValue(rememberMe);
          }

        if (allParams.projectId) {
            this.params.projectId = allParams.projectId;
        }

    }

    private getAuthError(error: UfRequestError): AppError {

        if (error.message === this.translate.instant(DiscoverTranslationKey.SelectProjectErrorNoProjects)) {
            return error;
        }

        if (isDictionary(error.data) && error.data.error === 'invalid_grant') {
            return this.errorService.createError(error.data.error_description, this.errorService.invalidUsernameOrPasswordErrorMessage);
        }

        if (error.type === ErrorType.Forbidden) {
            return this.errorService.createError(this.errorService.forbiddenRequestErrorMessage);
        }

        return this.errorService.createError(this.errorService.unhandledErrorMessage);
    }

    get canChangeCompany(): boolean {
        return !this.env.unifii.tenant;
    }

    get tenantSettings(): TenantSettings | undefined {
        return this.config.unifii.tenantSettings;
    }

}
