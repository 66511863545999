import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UfControl, UfControlGroup } from '@unifii/library/common';
import { Client, Interceptor, MeClient, OAuthWithPassword, PermissionAction, TenantClient, TokenStorageInterface, UfRequestError, UserInfo, ensureUfRequestError, isMfaErrorData } from '@unifii/sdk';
import { MeCompleteRegistrationFormControl, UserFieldLabelService, UserFormContext, UserFormResourceType, UserKeys, UserProvisioningProvider } from '@unifii/user-provisioning';

import { Config } from 'config';
import { MFAPath, ProjectSelectionPath, UserAccessRootPath } from 'discover/discover-constants';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { UserProvisioning } from 'shell/services/user-provisioning';
import { ShellTranslationKey } from 'shell/shell.tk';

import { MfaComponentNavigationState } from './mfa.component';

type CompleteRegistrationLinkParams = {
    tenant?: string;
    token?: string;
};

// temporary token storage
const tempTokenStorage: TokenStorageInterface & { _refreshToken: string | null } = {
    token: null,
    limitedToken: null,
    expiresAt: null,
    _refreshToken: null,
    setRefreshToken: (v: string | null) => {
        tempTokenStorage._refreshToken = v;

        return Promise.resolve();
    },
    getRefreshToken: () => {
        return Promise.resolve(tempTokenStorage._refreshToken);
    },
};

@Component({
    selector: 'ud-complete-registration',
    templateUrl: 'complete-registration.html',
})
export class CompleteRegistrationComponent implements OnInit, OnDestroy {

    protected readonly discoverTK = DiscoverTranslationKey;
    protected readonly controlKeys = UserKeys;
    protected form: UfControlGroup;
    protected linkError: AppError;
    protected error: AppError | null;
    protected busy = true;
    protected labels = inject(UserFieldLabelService).labelDictionary;
    protected emailControl?: UfControl;
    protected usernameControl?: UfControl;
    protected firstNameControl?: UfControl;
    protected lastNameControl?: UfControl;
    protected phoneControl?: UfControl;
    protected passwordControl?: UfControl;

    private user: UserInfo;
    private interceptor = inject(Interceptor);
    private authentication = inject(Authentication);
    private config = inject(Config);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private translate = inject(TranslateService);
    private errorService = inject(ErrorService);
    private userFormContext = inject(UserFormContext);
    private meCompleteRegistrationFormCtrl = inject(MeCompleteRegistrationFormControl);
    private userProvisioning = inject<UserProvisioning>(UserProvisioningProvider);

    // Create new client with temporary token storage
    private client = new Client(this.config.unifii, tempTokenStorage, this.interceptor);
    private tenantClient = new TenantClient(this.client);
    private meClient = new MeClient(this.client);

    async ngOnInit() {
        this.userFormContext.set(UserFormResourceType.Me, PermissionAction.Update);

        let errorMessage = this.translate.instant(DiscoverTranslationKey.CompleteRegistrationErrorInvalidLink) as string;

        try {
            const { tenant, token } = this.route.snapshot.queryParams as CompleteRegistrationLinkParams;

            if (!tenant || !token) {
                throw new Error();
            }
            this.config.unifii.tenant = tenant;
            this.config.unifii.tenantSettings = await this.tenantClient.getSettings();

            await this.client.authenticateWithInvitationToken({ invitation_token: token });
            this.user = await this.meClient.get();
            await this.amendUserProvisioningForNotAuthenticatedScope();

            // Clear username if it's an invitation_GUID
            if (this.user.username.startsWith('invitation_')) {
                this.user.username = '';
            }

            this.form = this.meCompleteRegistrationFormCtrl.buildRoot({ user: this.user, lockedConfig: undefined });
            this.emailControl = this.form.get(UserKeys.Email) as UfControl | undefined;
            this.usernameControl = this.form.get(UserKeys.Username) as UfControl | undefined;
            this.firstNameControl = this.form.get(UserKeys.FirstName) as UfControl | undefined;
            this.lastNameControl = this.form.get(UserKeys.LastName) as UfControl | undefined;
            this.phoneControl = this.form.get(UserKeys.Phone) as UfControl | undefined;
            this.passwordControl = this.form.get(UserKeys.Password) as UfControl | undefined;

            if (!this.passwordControl || !this.user.username || !this.user.email) {
                errorMessage = this.translate.instant(ShellTranslationKey.ErrorRequestUnauthorized);
                throw new Error();
            }

        } catch (e) {
            this.linkError = new UfRequestError(errorMessage);
        } finally {
            this.busy = false;
        }
    }

    ngOnDestroy() {
        this.revertAmendUserProvisioningForNotAuthenticatedScope();
    }

    protected async submit() {
        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        try {
            this.error = null;
            this.busy = true;

            // Create new client without token storage
            const meClient = new MeClient(
                new Client(this.config.unifii, undefined, this.interceptor),
            );

            // patch user with form values
            const user = Object.assign({}, this.user, this.meCompleteRegistrationFormCtrl.toDataModel(this.form));

            if (!user.password) {
                throw this.errorService.createError(this.errorService.invalidUsernameOrPasswordErrorMessage);
            }

            await meClient.update(user);
            await this.authentication.login({ username: user.username, password: user.password } satisfies OAuthWithPassword);
            this.revertAmendUserProvisioningForNotAuthenticatedScope();
            void this.router.navigate(['/', UserAccessRootPath, ProjectSelectionPath]);

        } catch (e) {

            const error = ensureUfRequestError(e);

            if (isMfaErrorData(error.data)) {
                const { mfaStatus, challenge, acceptedChallenges } = error.data;

                void this.router.navigate(['/', UserAccessRootPath, MFAPath], { state:
                    { mfaStatus, challenge, acceptedChallenges, nextRoute: ['/', UserAccessRootPath, ProjectSelectionPath] } satisfies MfaComponentNavigationState },
                );

                return;
            }

            this.error = error;

        } finally {
            this.busy = false;
        }
    }

    private async amendUserProvisioningForNotAuthenticatedScope() {
        const permissions = await this.meClient.getPermissions();

        this.authentication.userPermissions = PermissionsFunctions.normalizePermissions(permissions);

        // Skip v0 calls to check for username and email duplicated
        this.userProvisioning.skipCheckEmail = true;
        this.userProvisioning.skipGetUserByUsername = true;
    }

    private revertAmendUserProvisioningForNotAuthenticatedScope() {
        // permissions has already been updated by login process
        this.userProvisioning.skipCheckEmail = true;
        this.userProvisioning.skipGetUserByUsername = true;
    }

}
