import { Injectable, Type } from '@angular/core';
import { ContentField } from '@unifii/library/smart-forms';
import { FieldType } from '@unifii/sdk';

import { UsViewComponentRegistry } from 'shell/content/view-component-registry';

import { FileListDisplayComponent } from './file-list.component';

@Injectable()
export class UdViewComponentRegistry extends UsViewComponentRegistry {

    override get(type: FieldType): Type<ContentField> | null {

        if (type === FieldType.FileList) {
            return FileListDisplayComponent;
        }

        return super.get(type);
    }

}
