import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { SharedTermsTranslationKey } from '@unifii/library/common';
import { MeClient, MfaChallengeType, OAuthWithMfaRecoveryCode, OAuthWithMfaSms, OAuthWithVirtualMfa, SMSChallenge, UfRequestError, ensureUfRequestError, isArrayOfType, isBoolean, isDictionary, isOptionalType, isValueOfStringEnumType } from '@unifii/sdk';
import { MfaStatus } from '@unifii/user-provisioning';
import { isString } from 'markdown-it/lib/common/utils';

import { Config } from 'config';
import { PasswordChangePath } from 'discover/discover-constants';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { Authentication } from 'shell/services/authentication';
import { isPasswordChangeRequiredErrorData } from 'shell/services/shell-authentication.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

import { PasswordChangeComponentNavigationState, isPasswordChangeComponentNavigationState } from './password-change.component';

export interface MfaComponentNavigationState {
    mfaStatus: MfaStatus;
    challenge?: `${MfaChallengeType}`;
    acceptedChallenges?: string;
    rememberMe?: boolean;
    password?: string;
    params?: MfaPrams;
    nextState?: Record<string, unknown>;
    nextRoute: string[];
}

interface MfaPrams {
    projectId?: string;
}

export const isMfaComponentNavigationState = (data: unknown): data is MfaComponentNavigationState =>
    isDictionary(data) &&
    isValueOfStringEnumType(MfaStatus)(data.mfaStatus) &&
    isOptionalType(data.nextState, isDictionary) &&
    isArrayOfType(data.nextRoute, isString) &&
    isOptionalType(data.rememberMe, isBoolean) &&
    isOptionalType(data.password, isString) &&
    isOptionalType(data.challenge, isValueOfStringEnumType(MfaChallengeType)) &&
    isOptionalType(data.acceptedChallenges, isString) &&
    isOptionalType(data.params, isMfaParams);

const isMfaParams = (data: unknown): data is MfaPrams =>
    isDictionary(data) &&
    isOptionalType(data.projectId, isString);

@Component({
    selector: 'ud-mfa',
    templateUrl: 'mfa.html',
})
export class MFAComponent implements OnInit, OnDestroy {

    protected readonly sharedTermsTK = SharedTermsTranslationKey;
    protected readonly mfaChallengeType = MfaChallengeType;
    protected challenge?: `${MfaChallengeType}`;
    protected acceptedChallenges: `${MfaChallengeType}`[];
    protected issuer: string;
    protected label: string;
    protected mfaStatus: MfaStatus;
    protected inProgress = false;
    // authn
    protected user: PublicKeyCredentialUserEntity;

    private router = inject(Router);
    private userAccessManager = inject(UserAccessManager);
    private meClient = inject(MeClient);
    private config = inject(Config);
    private auth = inject(Authentication);
    private errorService = inject(ErrorService);
    private state: MfaComponentNavigationState = history.state; // type assumed by mfa-guard

    ngOnInit() {
       this.issuer = this.config.unifii.companyName ?? 'Unifii';
       this.label = `(${this.config.unifii.tenantSettings?.name}) ${this.auth.userInfo?.username ?? ''}`;
       this.mfaStatus = this.state.mfaStatus;
       this.challenge = this.state.challenge;
       this.acceptedChallenges = this.createAcceptedChallenges();

       if (this.auth.userInfo) {
            this.user = {
                id: new TextEncoder().encode(this.auth.userInfo.id),
                name: this.auth.userInfo.username,
                displayName: `${this.auth.userInfo.firstName} ${this.auth.userInfo.lastName}`,
            };
        }
    }

    ngOnDestroy() {
        this.userAccessManager.showError(null);
    }

    protected credential(credential: PublicKeyCredential): Promise<void> {
		console.log(credential);

		return Promise.resolve();
	}

    protected selectProvider(provider: MfaChallengeType) {
		this.challenge = provider;
        this.userAccessManager.showError(null);
	}

    protected async setVirtualMfaCode(secret: string): Promise<void> {
		await this.meClient.setVirtualMfaCode(secret);
	}

    protected smsChallenges(): Promise<SMSChallenge> {
        return this.meClient.smsChallenges();
	}

    protected async setRecoveryCodes(recoveryCodes: string[]): Promise<void> {
		await this.meClient.setRecoveryCodes(recoveryCodes);
        void this.router.navigate([...this.state.nextRoute, this.state.params ?? {}], { state: this.state.nextState });
	}

    protected async verifyRecoveryCode(recovery_code: string): Promise<void> {
        if (this.inProgress) {
            return;
        }

        this.inProgress = true;
        this.userAccessManager.showError(null);

        try {
            await this.auth.login({ recovery_code } satisfies OAuthWithMfaRecoveryCode, this.state.rememberMe);
            this.handleVerifyAccepted();
        } catch (e) {
            this.handleVerifyError(ensureUfRequestError(e));
        } finally {
            this.inProgress = false;
        }
	}

    protected async verifySmsCode(code: string, challenge: string): Promise<void> {
        if (this.inProgress) {
            return;
        }

        this.inProgress = true;
        this.userAccessManager.showError(null);

        try {
            await this.auth.login({ code, challenge } satisfies OAuthWithMfaSms);

            if (this.mfaStatus === MfaStatus.MfaSetupRequired) {
                this.meClient.setSmsMfaEnabled();
            }

            this.handleVerifyAccepted();
        } catch (e) {
            this.handleVerifyError(ensureUfRequestError(e));
        } finally {
            this.inProgress = false;
        }
	}

	protected async verifyVirtualMfaToken(mfa_token: string): Promise<void> {
        if (this.inProgress) {
            return;
        }

        this.inProgress = true;
        this.userAccessManager.showError(null);

        try {
            await this.auth.login({ mfa_token } satisfies OAuthWithVirtualMfa, this.state.rememberMe);
            this.handleVerifyAccepted();
        } catch (e) {
            this.handleVerifyError(ensureUfRequestError(e));
        } finally {
            this.inProgress = false;
        }
	}

    protected logout() {
        if (this.inProgress) {
            return;
        }

        void this.auth.logout();
    }

    private handleVerifyAccepted() {
        if (this.mfaStatus === MfaStatus.MfaSetupRequired && this.auth.userInfo?.mfa?.hasRecoveryCodes === false) {
            this.challenge = MfaChallengeType.RecoveryCode;
            this.acceptedChallenges = [MfaChallengeType.RecoveryCode];

            return;
        }

        void this.router.navigate([...this.state.nextRoute, this.state.params ?? {}], { state: this.state.nextState });
    }

    private handleVerifyError(error: UfRequestError): void {

        if (isPasswordChangeRequiredErrorData(error.data)) {

            if (isPasswordChangeComponentNavigationState(this.state.nextState)) {
                void this.router.navigate([...this.state.nextRoute, this.state.params ?? {}], { state: this.state.nextState });

                return;
            }

            void this.router.navigate(['/', PasswordChangePath], { state: { oldPassword: this.state.password, params: this.state.params } satisfies PasswordChangeComponentNavigationState });

            return;
        }

        this.userAccessManager.showError(this.getAuthError(error));
    }

    private getAuthError(error: UfRequestError): AppError {

        if (isDictionary(error.data) && error.data.error === 'invalid_grant') {
            return this.errorService.createError(error.data.error_description, error);
        }

        if (error.message) {
            this.errorService.createError(error.message, error);
        }

        return this.errorService.createError(this.errorService.unhandledErrorMessage, error);
    }

    private createAcceptedChallenges(): `${MfaChallengeType}`[] {

        let acceptedChallenges: `${MfaChallengeType}`[] = [];

        if (this.state.acceptedChallenges) {
            acceptedChallenges = this.state.acceptedChallenges.split(',').filter((challenge): challenge is `${MfaChallengeType}` => isValueOfStringEnumType(MfaChallengeType)(challenge));
        } else {
            acceptedChallenges = Object.values(MfaChallengeType);

            // remove sms if tenant doesn't support sms
            if (!this.config.unifii.tenantSettings?.isSmsMfaEnabled) {
                acceptedChallenges = acceptedChallenges.filter((challenge) => challenge !== MfaChallengeType.Sms);
            }

            // TODO remove virtual mfa if tenant doesn't support virtual mfa
        }

        return acceptedChallenges;
    }

}
