<uf-panel [class.scroll-all]="table" [class.col]="table" class="container bg-gray">
    <div class="wrapper">
        <div [class.disable-scroll]="showFilters" class="report-panel">
            <div class="header-pane uf-app-bar flat stretch">
                <button (click)="print()" type="button" class="uf-action">
                    <uf-icon name="print" />
                </button>
            </div>

            <div *ngIf="dateFilterConfigs" class="uf-grid gap-bottom pad-sides">
                <div *ngIf="dateFilterConfigs.startDate || this.dateFilterConfigs.endDate" class="col-lg-6">
                    <div class="uf-grid gap-bottom">
                        <uf-date *ngIf="dateFilterConfigs.startDate"
                            [label]="shellTK.ReportFilterStartDateLabel | translate" [value]="start"
                            (valueChange)="startChange($event)" class="col-6" format="d MMM yyyy" />
                        <uf-date *ngIf="dateFilterConfigs.endDate"
                            [label]="shellTK.ReportFilterEndDateLabel | translate" [value]="end"
                            (valueChange)="endChange($event)" class="col-6" format="d MMM yyyy" />
                    </div>
                    <div *ngIf="dateFilterConfigs.startDate && this.dateFilterConfigs.endDate && this.dateFilterConfigs.presetRanges"
                        class="row space-children wrap">
                        <button (click)="dateIntervalPresetSelected(i)" type="button"
                            *ngFor="let preset of dateIntervalPresets; let i = index"
                            class="uf-button tertiary x-small">{{preset.label}}
                        </button>
                    </div>
                </div>
                <div *ngIf="dateFilterConfigs.intervals" class="col-lg-3">
                    <uf-radio [label]="shellTK.ReportFilterDateIntervalsLabel | translate"
                        [columns]="incrementOptions.length" [options]="incrementOptions" [columns]="3"
                        [value]="interval" (valueChange)="incrementChange($event)" valueProperty="identifier" />
                </div>
            </div>

            <div *ngIf="filterManagerMainPage.entries.length && filterValues" class="col-lg-3">
                <uf-filter-inputs [manager]="filterManagerMainPage" [value]="filterValues"
                    (valueChange)="filtersChange()" />
            </div>

            <div *ngIf="!error" class="uf-grid condensed column-gap-sm pad-sides gap-bottom report">
                <div class="col-12">
                    <uf-filter-display [manager]="filterManagerCombined" [value]="filterValues"
                        (valueChange)="filtersChange()" />
                </div>

                <uf-message *ngFor="let reportNotFound of reportsNotFound" [content]="reportNotFound" icon="warning"
                    class="col-12 warning gap-bottom" />

                <us-report *ngFor="let reportConfig of reportConfigs" class="col-lg-{{reportConfig.width}}"
                    [reportConfig]="reportConfig" [class.paginated]="table" />
            </div>

        </div>

        <div class="drawer-container">
            <uf-drawer [showHandle]="true" [(open)]="showFilters" class="share-space">
                <uf-filter-inputs *ngIf="filterManagerPanel.entries.length && filterValues"
                    [manager]="filterManagerPanel" [value]="filterValues" (valueChange)="filtersChange()"
                    (onClose)="showFilters = false" />
            </uf-drawer>
        </div>

    </div>
</uf-panel>